import * as React from "react"
import { navigate, Link } from "gatsby"
import "@fontsource/source-sans-pro"
import "../styles/custom.sass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { PageHead } from "../components/page-head"

// markup
const NotFoundPage = () => {
  return (
    <main className="container my-5 pt-5">
      <div className="row my-5 pt-5 px-4 px-md-0">
        <div className="col-md-6 my-5 px-4 bg-gard-grey text-center shadow-lg mx-auto" style={{ backgroundColor: "#f2f2f2" }}>
          <h1 className="mt-5 fs-1">404 Page not found</h1>
          <p className="h4 mt-4 mb-5">Sorry! We couldn’t find what you were looking for.</p>
          <button onClick={() => navigate(-1)} id="getAccessButton" className="btn bg-medico-green py-2 px-4 px-md-4 mb-5 me-3 text-white shadow-sm d-inline-flex align-items-center">
            <FontAwesomeIcon icon={faChevronLeft} className="me-2" /> Back
          </button>
          <Link to="/" id="getAccessButton" className="btn bg-medico-green py-2 px-4 px-md-4 mb-5 text-white lh-base shadow-sm">
            Home
          </Link>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage

export const Head = () => (
  <>
    <PageHead subTitle="404 Page Not Found" />
  </>
)