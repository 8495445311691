import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export const PageHead = ({ title, subTitle, description, themeColor, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    themeColor: defaultThemeColor
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    subTitle: subTitle || "",
    description: description || defaultDescription,
    url: siteUrl,
    pathName: pathname || '',
    themeColor: themeColor || defaultThemeColor,
  }

  return (
    <>
      <title>{seo.title}{seo.subTitle ? " - " + seo.subTitle : ""}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
      <meta name="theme-color" content={seo.themeColor} />
      {pathname ? <link rel="canonical" href={`${seo.url}${seo.pathName}`} /> : '' }
      {children}
    </>
  )
}